:root {
  --app-header-height-internal: 4em;
  --app-header-height: calc(var(--app-header-height-internal) + 4px);
}

.AppHeader {
  /*height: var(--app-header-height);*/
}

.AppHeader {
  border: 1px solid black;
  height: var(--app-header-height-internal);
  display: flex;
  flex-direction: row;
  padding: 1em;
  padding-left: 1em;
  background-color: var(--header-color);
}

.AppHeader img {
  height: var(--app-header-height-internal);
  width: auto;
  height: 100%;
}

.app_info {
  /* border: 1px solid green; */

  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  padding: 1em;
  padding-left: 2em;
  margin-left: 1em;
}

.project_name {
  text-align: left;
  /* width: 100%; */
  flex-direction: row;
  font-size: 1.4em !important;
  color: var(--gold-col) !important;
  font-weight: 300;
  border: none !important;
}

.project_name fieldset {
  border: none !important;
}

.project_name svg {
  fill: var(--light-grey-divider);
}

.project_name_container {
  display: flex;
  flex-direction: row;
}

.user_info {
  text-align: right;
  flex-direction: row;
  flex-grow: 1;
  font-size: 0.8rem;
  color: var(--primary-text);
}
