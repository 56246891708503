.dayChangeButton {
    border-radius: 0;
}

.task_bar_group_settings {
  /*border-top: 1px solid var(--light-grey-divider);*/
  margin-left: var(--taskbar-margin-left);
  display: flex;
  flex-direction: column;
  padding-top: 1em;
  padding-right: var(--taskbar-margin-right);
}