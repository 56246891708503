.map_legend {
  position: absolute;
  right: 1em;
  bottom: 1em;
  background-color: var(--workspace-background-col);
  padding: 1em 2em;
  z-index: 800;
  box-shadow: 1px 0px 10px 0 #0008;
  min-width: 30em;
}

.legend_slider {
  margin-top: 1.7em;
}

.legend_slider_short {
  composes: legend_slider;
  margin-top: 0.5em;
}

.map_legend_upper_label {
  transform: translateY(calc(-0.8em - (3 * 0.8rem) - 6px));
  font-size: 0.8rem;
}

.map_legend_lower_label {
}

.volume_thickness_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.8em;
}

.volume_thickness_labels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.3em;
}

.volume_thickness_lines {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.volume_thickness_lines span {
  width: 100%;
  height: 1px;
  background-color: #bd5559;
}

.volume_thickness_lines span:nth-child(1) {
  height: 2px;
}

.volume_thickness_lines span:nth-child(2) {
  height: 4px;
}

.volume_thickness_lines span:nth-child(3) {
  height: 6px;
}
