.tooltip,
button.info_hover {
  background-color: color-mix(in srgb, var(--gold-col) 75%, transparent);
  padding: 0.1em;
  margin-left: 0.5em;
}

.tooltip:hover,
button.info_hover:hover {
  background-color: color-mix(in srgb, var(--gold-col) 100%, transparent);
}

.tooltip svg,
button.info_hover svg {
  height: 0.5em;
  width: 0.5em;
  fill: black;
}
