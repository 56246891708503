.nav_bar {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  width: var(--navbar-width);
  min-width: var(--navbar-width);
  border: 1px solid black;
  padding: 0;
  background-color: var(--navbar-background-color);
}

/* border: 0,
borderLeft: chosen ? 6 : 0,
borderColor: '#0000ff',
borderRadius: 0, */

.nav_button {
  font-size: 1.2rem;
  /* font-weight: bold; */
  cursor: pointer;
  color: var(--primary-text);
  height: 3.5rem;
  background-color: var(--navbar-background-color);
  border: 0px;
  text-align: left;
  padding-left: 3px;
  font-weight: normal !important;
}

.nav_button_disabled {
  color: var(--navbar-background-color);
}

.nav_button_disabled:hover {
  background: none !important;
}

.nav_button_chosen {
  composes: nav_button;
  padding-left: 0;
  border-left: 4px solid white;
}
.nav_button_chosen > div {
  padding-left: 30px;
  height: 100%;
  background-color: #0000 !important;
  /*#FFF2 !important;*/
}

.nav_button_chosen > div > div > span {
  font-weight: 500 !important;
}

.nav_button_disabled > div {
  color: var(--navbar-background-color);
}

.nav_button_disabled > div:hover {
  background: none !important;
}

.nav_button_chosen:hover,
.nav_button_chosen:focus,
.nav_button:hover,
.nav_button:focus {
  background-color: grey;
}

.list_item_button > div > span {
  font-weight: normal !important;
}

.list_item_button:hover {
  background: none !important;
}
