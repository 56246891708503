.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  float: left;
  background-color: var(--workspace-background-col);
  color: var(--primary-text) !important;
}

.mapboxgl-ctrl-attrib a,
.mapboxgl-ctrl-attrib a:hover {
  color: var(--primary-text) !important;
}

.mapbox-improve-map {
  display: none;
}

.mapboxgl-ctrl-group {
  background-color: var(--background-col) !important;
}

#car-showhide {
  -webkit-mask-image: url(car.svg);
  mask-image: url(car.svg);
  background-color: grey;
}

#ped-showhide {
  -webkit-mask-image: url(pedestrian.svg);
  mask-image: url(pedestrian.svg);
  background-color: grey;
}

#chart-showhide {
  -webkit-mask-image: url(chart.svg);
  mask-image: url(chart.svg);
  background-color: grey;
}

#map-recenter {
  -webkit-mask-image: url(init_position.svg);
  mask-image: url(init_position.svg);
  background-color: grey;
}

#ticks-showhide {
  -webkit-mask-image: url(ticks.svg);
  mask-image: url(ticks.svg);
  background-color: grey;
}

#map-settings-control {
  -webkit-mask-image: url(settings_icon.svg);
  mask-image: url(settings_icon.svg);
  background-color: grey;
}

.enabled .mapboxgl-ctrl-icon {
  background-color: var(--gold-col) !important;
}

.showhide .mapboxgl-ctrl-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
  height: 100%;
  width: 100%;
}

.showhide button {
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-size: 100%;
  mask-position: center;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23FFDA6A'%3E%3Cpath d='M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z'/%3E%3C/svg%3E") !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23FFDA6A'%3E%3Cpath d='M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.mapboxgl-popup {
  position: absolute;
  top: 1em;
  left: 1em;
  transform: none !important;
}

.mapboxgl-popup-content {
  color: var(--primary-text);
  background-color: var(--workspace-background-col);
  text-align: left;
  padding-left: 2em;
  padding-right: 2em;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px 0px #0008;
}

.mapboxgl-popup-content h1 {
  font-size: 1rem;
  font-weight: bold;
}

.mapboxgl-popup-content table td:first-child {
  text-align: left;
}

.mapboxgl-popup-content table td:last-child {
  text-align: right;
  padding-left: 0.5em;
}

.mapboxgl-popup-content table tr.space-under > td {
  padding-bottom: 1em;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: var(--workspace-background-col) !important;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: var(--workspace-background-col) !important;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: var(--workspace-background-col) !important;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: var(--workspace-background-col) !important;
}
