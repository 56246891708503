.task_bar_tab:not([hidden]) {
    /* overflow-y: auto;
    overflow-x: hidden; */
    display:flex;
    flex-direction: column;
}

.taskbar_top_tabs {
  margin-top: calc(-1* var(--taskbar-padding-top));
  padding-left: var(--taskbar-tabs-horiz-margin);
  padding-right: var(--taskbar-tabs-horiz-margin);
}